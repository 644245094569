<template>
  <v-container class="general">
    <!-- Steps -->
    <AppStepperHeader
      v-model="groupWizardStepper"
      :steps="steps"
      :loading="isLoading"
    />
    <v-window
      v-model="groupWizardStepper"
      class="sw-mb-4"
    >
      <v-window-item
        v-for="(step, i) in steps"
        :key="`content-${i}`"
        :value="i"
        :class="{ loading: isLoading }"
      >
        <component
          :is="step.component"
          @administrator-created="listGroupAdministrators"
          @administrator-updated="listGroupAdministrators"
          @administrator-deleted="listGroupAdministrators"
        ></component>
      </v-window-item>
    </v-window>
    <!-- Actions -->
    <template v-if="activeStep !== 'done'">
      <v-btn
        round
        large
        class="sw-my-0 sw-ml-0 sw-mr-1 white sw-accent text-none"
        @click="goToGroupList"
        :disabled="isLoading"
        >{{ $t("common.cancel") }}</v-btn
      >
      <v-btn
        round
        large
        class="sw-my-0 sw-ml-0 sw-mr-1 sw-accent-bg sw-on-accent text-none"
        :loading="isLoading"
        @click="changeNext"
        >{{ $t("common.continue") }}</v-btn
      >
      <span
        v-if="activeStep === 'settings'"
        class="caption grey--text"
        >{{ processName }}</span
      >
    </template>
  </v-container>
</template>

<script>
import AppStepperHeader from "@/components/AppStepperHeader.vue";
import OverallSettings from "@/components/GroupWizard/OverallSettings.vue";
import MessageSettings from "@/components/GroupWizard/MessageSettings.vue";
import Administrators from "@/components/GroupWizard/Administrators.vue";
import Confirmation from "@/components/GroupWizard/Confirmation.vue";
import Done from "@/components/GroupWizard/Done.vue";
import { features } from "@/components/GroupWizard/Configs/features.json";
import "flag-icon-css/css/flag-icon.css";
import { languageIcons } from "@/language-icons.json";

export default {
  provide() {
    return {
      parentValidator: this.$validator,
    };
  },
  data: () => ({
    isLoading: false,
    processName: "",
  }),
  computed: {
    isLandingPageEnabled() {
      return process.env.VUE_APP_LANDINGPAGE_ENABLED === "true";
    },
    languageIcons() {
      return languageIcons || [];
    },
    appDefaultLanguage() {
      return process.env.VUE_APP_LANGUAGES.split(",")[0];
    },
    currentUserRoles() {
      return this.$store.getters.currentUser.roles;
    },
    isAdmin() {
      return this.currentUserRoles.includes("admin");
    },
    group: {
      get() {
        return this.$store.getters.groupWizardGroup;
      },
      set(v) {
        this.$store.dispatch("setGroupWizardGroup", v);
      },
    },
    groupPaymentsEnabled: {
      get() {
        return this.$store.getters.groupWizardGroupPaymentsEnabled;
      },
      set(v) {
        this.$store.dispatch("setGroupWizardGroupPaymentsEnabled", v);
      },
    },
    groupCurrency: {
      get() {
        return this.$store.getters.groupWizardGroupCurrency;
      },
      set(v) {
        this.$store.dispatch("setGroupWizardGroupCurrency", v);
      },
    },
    groupDefaultLanguage() {
      if (
        !this.group ||
        !this.group.language_iso ||
        !this.group.language_iso.value
      ) {
        return "";
      }

      return this.group.language_iso.value;
    },
    emailSenderDomains() {
      return this.$store.getters.currentUser.email_sender_domains;
    },
    emailSenderAddress() {
      return process.env.VUE_APP_EMAIL_SENDER_ADDRESS;
    },
    groupId() {
      return this.$route.params.group_id;
    },
    steps() {
      return [
        {
          name: "settings",
          title: this.$t("group_wizard.overall_settings"),
          component: OverallSettings,
          enabled: true,
        },
        {
          name: "messages",
          title: this.$t("group_wizard.message_settings"),
          component: MessageSettings,
          enabled: true,
        },
        {
          name: "administrators",
          title: this.$t("group_wizard.add_administrators"),
          component: Administrators,
          enabled: true,
        },
        {
          name: "confirmation",
          title: this.$t("group_wizard.confirmation"),
          component: Confirmation,
          enabled: true,
        },
        {
          name: "done",
          title: this.$t("group_wizard.done"),
          component: Done,
          enabled: true,
        },
      ].filter((el) => el.enabled);
    },
    groupWizardStepper: {
      get() {
        return this.$store.getters.groupWizardStepper;
      },
      set(v) {
        this.$store.dispatch("setGroupWizardStepper", v);
      },
    },
    activeStep() {
      return this.steps[this.groupWizardStepper].name;
    },
  },
  components: {
    AppStepperHeader,
  },
  mounted() {
    this.groupWizardStepper = 0;
  },
  watch: {
    groupId: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.group = {};
          this.groupPaymentsEnabled = false;
          return;
        }

        this.listGroupAdministrators();

        if (val === this.group.id) return;

        this.group = {};
        this.groupPaymentsEnabled = false;
        this.getGroup(val);
      },
    },
    activeStep: {
      immediate: true,
      handler() {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
    },
  },
  methods: {
    stepClass(i) {
      return [
        "pa-3 text-center step",
        {
          complete: i < this.groupWizardStepper,
          editable: i < this.groupWizardStepper && this.activeStep !== "done",
          active: i === this.groupWizardStepper,
        },
      ];
    },
    goToGroupList() {
      this.$router
        .push({
          name: "groups",
        })
        .catch(() => {});
    },
    async changeNext() {
      if (!this.group.id) {
        this.createGroup();
        return;
      }

      if (this.activeStep === "settings") {
        this.updateGroup();
        return;
      }

      if (this.activeStep === "messages") {
        this.updateGroupMessageSettings();
        return;
      }

      if (this.activeStep === "administrators") {
        this.groupWizardStepper += 1;
        return;
      }

      if (this.activeStep === "confirmation") {
        this.confirmGroup();
        return;
      }
    },
    async createFeatures() {
      if (!this.group.id) return;

      try {
        for await (const menu of features) {
          const groupMenusListResponse = await this.$api.groupMenus.list(
            this.group.id,
          );

          if (
            !groupMenusListResponse.data ||
            !groupMenusListResponse.data.data
          ) {
            return;
          }

          let groupMenu = groupMenusListResponse.data.data.find(
            (el) => el.slug === menu.slug,
          );

          if (!groupMenu || !groupMenu.id) {
            this.processName = "Creating menu...";

            const lang =
              menu.slug === "group_menu"
                ? this.appDefaultLanguage
                : this.groupDefaultLanguage;

            const groupMenuCreateSpecs = [
              this.group.id,
              {
                name: menu.name,
                slug: menu.slug,
                enabled: 1,
                lang: lang,
              },
            ];

            const groupMenuCreateResponse = await this.$api.groupMenus.create(
              ...groupMenuCreateSpecs,
            );

            if (
              !groupMenuCreateResponse.data ||
              !groupMenuCreateResponse.data.data
            ) {
              return;
            }

            groupMenu = groupMenuCreateResponse.data.data;
          }

          for await (const destination of menu.destinations) {
            let destinationGroupPlugin = {};
            let destinationParams = "";

            if (destination.group_plugin) {
              const groupPluginsListSpecs = [
                this.group.id,
                {
                  prefix: destination.group_plugin.prefix,
                  with_attributes: 1,
                  expand_attributes: 1,
                },
              ];

              const groupPluginsListResponse =
                await this.$api.groupPlugins.list(...groupPluginsListSpecs);

              if (
                !groupPluginsListResponse.data ||
                !groupPluginsListResponse.data.data
              ) {
                return;
              }

              destinationGroupPlugin = groupPluginsListResponse.data.data.find(
                (el) => el.attributes.uid === destination.group_plugin.uid,
              );

              if (!destinationGroupPlugin || !destinationGroupPlugin.id) {
                this.processName = "Creating plugin...";

                const groupPluginsCreateSpecs = [
                  this.group.id,
                  {
                    uid: destination.group_plugin.uid,
                    prefix: destination.group_plugin.prefix,
                    name:
                      destination.group_plugin.name ||
                      destination.group_plugin.uid,
                    lang: this.groupDefaultLanguage,
                  },
                ];

                const groupPluginsCreateResponse =
                  await this.$api.groupPlugins.create(
                    ...groupPluginsCreateSpecs,
                  );

                if (
                  !groupPluginsCreateResponse.data ||
                  !groupPluginsCreateResponse.data.data
                ) {
                  return;
                }

                destinationGroupPlugin = groupPluginsCreateResponse.data.data;
              }
            }

            if (destination.params) {
              if (destination.component === "AudienceListPage") {
                this.processName = "Creating audience...";

                for (const group_audience of destination.params
                  .group_audience) {
                  const groupAudiencesCreateSpecs = [
                    this.group.id,
                    { ...group_audience, lang: this.groupDefaultLanguage },
                  ];

                  const groupAudiencesCreateResponse =
                    await this.$api.groupAudiences.create(
                      ...groupAudiencesCreateSpecs,
                    );

                  if (
                    !groupAudiencesCreateResponse.data ||
                    !groupAudiencesCreateResponse.data.data
                  ) {
                    return;
                  }
                }
              }

              if (destination.component === "ArticlePage") {
                this.processName = "Creating page...";

                const groupArticlesCreateSpecs = [
                  this.group.id,
                  {
                    name: destination.params.group_article.name,
                    body: destination.params.group_article.body,
                    group_plugin_id: destinationGroupPlugin.id,
                    lang: this.groupDefaultLanguage,
                  },
                ];

                const groupArticlesCreateResponse =
                  await this.$api.groupArticles.create(
                    ...groupArticlesCreateSpecs,
                  );

                if (
                  !groupArticlesCreateResponse.data ||
                  !groupArticlesCreateResponse.data.data
                ) {
                  return;
                }

                destinationParams = JSON.stringify({
                  article_id: groupArticlesCreateResponse.data.data.id,
                });
              }
            }

            this.processName = "Creating destination...";

            const lang =
              menu.slug === "group_menu"
                ? this.appDefaultLanguage
                : this.groupDefaultLanguage;

            const groupMenuDestinationsCreateSpecs = [
              this.group.id,
              groupMenu.id,
              {
                name: this.$t(destination.name),
                icon: destination.icon,
                component: destination.component,
                params: destinationParams || null,
                sort_order: destination.sort_order,
                group_plugin_id: destinationGroupPlugin.id || null,
                is_public: destination.is_public,
                enabled: destination.enabled,
                lang: lang,
              },
            ];

            const groupMenuDestinationsCreateResponse =
              await this.$api.groupMenuDestinations.create(
                ...groupMenuDestinationsCreateSpecs,
              );

            if (
              !groupMenuDestinationsCreateResponse.data ||
              !groupMenuDestinationsCreateResponse.data.data
            ) {
              return;
            }
          }
        }

        this.isLoading = false;
        this.groupWizardStepper += 1;
        this.processName = "";
        this.errors.clear();
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.processName = "";
        }
      }
    },
    transformGroupForCreate() {
      let group = {
        name: this.group.name,
        subdomain: this.group.subdomain,
        group_image: this.group.group_image ? this.group.group_image.id : null,
        published: 0,
        protected: 0,
        supported_languages: this.group.supported_languages
          .map((el) => el.value)
          .toString(),
        language_iso: this.group.language_iso.value,
        email_sender_address: this.emailSenderAddress,
        with_attributes: 1,
        expand_attributes: 1,
      };

      if (this.groupPaymentsEnabled && this.isAdmin) {
        group.payments_enabled = this.groupPaymentsEnabled ? 1 : 0;
        group.currency = this.groupCurrency;
        group.default_tax_rate = this.group.default_tax_rate;
        group.platform_fee = this.transformPriceForSave(
          this.group.platform_fee,
        );
      }

      if (this.isLandingPageEnabled) {
        group = {
          ...group,
          lp_event_timer: 0,
          lp_transparent_toolbar: 0,
          lp_primary_color: "#1f363d",
          lp_secondary_color: "#008f8c",
          primary_color: "#1f363d",
          secondary_color: "#008f8c",
        };
      }

      return group;
    },
    async createGroup() {
      const isValid = await this.$validator.validateAll("overall-settings");

      if (!isValid) return;

      this.isLoading = true;

      try {
        const specs = this.transformGroupForCreate(this.group);

        const response = await this.$api.groups.create(specs);

        if (!response.data || !response.data.data) {
          return;
        }

        const groupId = response.data.data.id;

        this.errors.clear();

        await this.getGroupSilent(groupId);
        await this.createFeatures();

        this.$router
          .push({
            name: "groups_edit_wizard",
            params: { group_id: groupId },
          })
          .catch(() => {});
      } catch (error) {
        if (error) {
          this.isLoading = false;

          if (error.response && error.response.data.error.message) {
            switch (error.response.data.error.message) {
              case "The subdomain may only contain letters, numbers, dashes and underscores.":
                this.errors.add({
                  msg: this.$t(
                    "group_wizard.subdomain_illegal_characters_error",
                  ),
                  scope: "overall-settings",
                  field: "subdomain",
                });
                break;
              case "The subdomain has already been taken.":
                this.errors.add({
                  msg: this.$t("group_wizard.subdomain_already_taken_error"),
                  scope: "overall-settings",
                  field: "subdomain",
                });
                break;
              default:
                this.errorMessageShow(error);
            }
          }
        }
      }
    },
    mapLanguages(value) {
      if (!value) return [];

      return value.split(",").map((lang) => {
        const icon = this.languageIcons.find((el) => el.key === lang);

        if (!icon) return;

        return {
          name: this.$t(`common.${lang}_label`),
          value: lang,
          iso2: icon.iso2,
        };
      });
    },
    transformGroupForEdit(model) {
      const hasUseAllowDomains =
        this.emailSenderDomains &&
        (this.emailSenderDomains || "").split(",").length > 0;

      const attributes = {
        is_complete: model.attributes.is_complete,
        email_sender_address: hasUseAllowDomains
          ? model.attributes.email_sender_address
          : this.emailSenderAddress,
        email_sender_name: model.attributes.email_sender_name,
        sms_sender_name: model.attributes.sms_sender_name,
        sms_encoding: model.attributes.sms_encoding,
        supported_languages: this.mapLanguages(
          model.attributes.supported_languages,
        ),
        language_iso: {
          name: this.$t(model.attributes.language_iso),
          value: model.attributes.language_iso,
        },
        group_image: model.attributes.group_image,
      };

      let group = {
        id: model.id,
        name: model.name,
        subdomain: model.subdomain,
        payments_enabled: model.payments_enabled,
        currency: model.currency,
        default_tax_rate: model.default_tax_rate,
        platform_fee: this.transformPriceForEdit(model.platform_fee),
        wallet_user_id: model.wallet_user_id,
        group_package_slug: model.group_package_slug,
        published: model.published,
        ...attributes,
      };

      return group;
    },
    async getGroupSilent(groupId) {
      const params = [groupId, { with_attributes: 1, expand_attributes: 1 }];

      const response = await this.$api.groups.get(...params);

      if (!response.data || !response.data.data) {
        return;
      }

      this.group = this.transformGroupForEdit(response.data.data);

      this.$store.dispatch("setActiveGroup", response.data.data);

      this.groupPaymentsEnabled = this.group.payments_enabled;
      this.groupCurrency = this.group.currency;
    },
    async getGroup(groupId) {
      if (!groupId) {
        return;
      }

      try {
        this.isLoading = true;

        await this.getGroupSilent(groupId);

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    transformGroupForUpdate(model) {
      let group = {
        name: model.name,
        subdomain: model.subdomain,
        group_image: model.group_image ? model.group_image.id : null,
        supported_languages: model.supported_languages
          .map((el) => el.value)
          .toString(),
        language_iso: model.language_iso.value,
        with_attributes: 1,
        expand_attributes: 1,
      };

      if (this.groupPaymentsEnabled && this.isAdmin) {
        group.payments_enabled = 1;
        group.currency = this.groupCurrency;
        group.default_tax_rate = model.default_tax_rate;
        group.platform_fee = this.transformPriceForSave(model.platform_fee);
      }

      return group;
    },
    async updateGroup() {
      const isValid = await this.$validator.validateAll("overall-settings");

      if (!isValid) return;

      this.isLoading = true;

      try {
        const specs = [this.group.id, this.transformGroupForUpdate(this.group)];

        const response = await this.$api.groups.update(...specs);

        if (!response.data || !response.data.data) {
          return;
        }

        await this.getGroupSilent(this.group.id);

        this.isLoading = false;
        this.groupWizardStepper += 1;
        this.errors.clear();
      } catch (error) {
        if (error) {
          this.isLoading = false;

          if (error.response && error.response.data.error.message) {
            switch (error.response.data.error.message) {
              case "The subdomain may only contain letters, numbers, dashes and underscores.":
                this.errors.add({
                  msg: this.$t(
                    "group_wizard.subdomain_illegal_characters_error",
                  ),
                  scope: "overall-settings",
                  field: "subdomain",
                });
                break;
              case "The subdomain has already been taken.":
                this.errors.add({
                  msg: this.$t("group_wizard.subdomain_already_taken_error"),
                  scope: "overall-settings",
                  field: "subdomain",
                });
                break;
              default:
                this.errorMessageShow(error);
            }
          }
        }
      }
    },
    async updateGroupMessageSettings() {
      const isValid = await this.$validator.validateAll("message-settings");

      if (!isValid) return;

      this.isLoading = true;

      try {
        const specs = [
          this.group.id,
          {
            email_sender_address: this.group.email_sender_address,
            email_sender_name: this.group.email_sender_name,
            sms_sender_name: this.group.sms_sender_name,
            with_attributes: 1,
            expand_attributes: 1,
          },
        ];

        const response = await this.$api.groups.update(...specs);

        if (!response.data || !response.data.data) {
          return;
        }

        await this.getGroupSilent(this.group.id);

        this.isLoading = false;
        this.groupWizardStepper += 1;
        this.errors.clear();
      } catch (error) {
        this.isLoading = false;
        this.errorMessageShow(error);
      }
    },
    async confirmGroup() {
      try {
        this.isLoading = true;

        const specs = [
          this.group.id,
          {
            published: 1,
            is_complete: 1,
          },
        ];

        const response = await this.$api.groups.update(...specs);

        if (!response.data || !response.data.data) {
          return;
        }

        await this.getGroupSilent(this.group.id);

        this.isLoading = false;
        this.groupWizardStepper += 1;
        this.errors.clear();
      } catch (error) {
        if (error) {
          this.errorMessageShow(error);
          this.isLoading = false;
        }
      }
    },
    listGroupAdministrators() {
      const params = [
        this.groupId,
        {
          per_page: 100,
        },
      ];

      this.$api.groupAdministrators.list(...params).then(
        (response) => {
          if (!response.data || !response.data.data) {
            return;
          }

          this.$store.dispatch(
            "setGroupWizardAdministrators",
            response.data.data,
          );
        },
        () => {},
      );
    },
    transformPriceForSave(price) {
      if (!price) {
        return null;
      }
      return Number(price) * 100;
    },
    transformPriceForEdit(price) {
      if (!price) {
        return null;
      }
      return (Number(price) / 100).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  opacity: 0.4;
  transition: all 0.2s;
  pointer-events: none;
}
</style>
